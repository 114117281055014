import * as React from 'react';
import { MyAppContext, myAppStateReducer, useAuth } from '@snapi/ui-utils';
import { Baby, uuid } from '@snapi/types';

type MyAppContextProviderProps = {
  children: React.ReactNode;
};

export function MyAppContextProvider({ children }: MyAppContextProviderProps) {
  const [state, dispatch] = React.useReducer(myAppStateReducer, {
    selected: null,
    invited: false,
    userId: null,
  });

  const auth = useAuth();
  React.useEffect(() => {
    if (state.userId !== auth.state.user?.id) {
      dispatch({ type: 'SET_USER_ID', payload: auth.state.user?.id || null });
    }
  }, [state.userId, auth.state.user]);

  const setSelected = (selected: Baby & { parent_id: uuid }) => {
    dispatch({ type: 'SET_SELECTED', payload: selected });
  };

  return (
    <MyAppContext.Provider value={{ state, setSelected }}>
      {children}
    </MyAppContext.Provider>
  );
}
