import { AppProps } from 'next/app';
import Head from 'next/head';

import 'core-js/proposals/promise-finally';
import '../styles/global.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-notion-x/src/styles.css';

import { AuthProvider } from '@snapi/auth-provider';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from '@snapi/query';
import { Navigation } from '../components/navigation';
import { MyAppContextProvider } from '../utils/my-app-context-provider';
import useGoogleTagManager from '../components/use-google-tag-manager';

function CustomApp({ Component, pageProps }: AppProps) {
  useGoogleTagManager('GTM-5FBPXS7');

  return (
    <>
      <Head>
        <title>Snapihealth</title>
      </Head>
      <main className="h-screen w-screen pt-10 md:pt-[60px] overflow-auto">
        <AuthProvider>
          <MyAppContextProvider>
            <ApolloProvider client={GraphQlClient.getClient()}>
              <Navigation />
              <Component {...pageProps} />
            </ApolloProvider>
          </MyAppContextProvider>
        </AuthProvider>
      </main>
    </>
  );
}

export default CustomApp;
