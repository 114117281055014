import { Taxonomy } from '@snapi/types';
import capitalize from 'lodash.capitalize';

export const bacteriaName = (name: string, taxonomy: Taxonomy) => {
  if (taxonomy === Taxonomy.species) {
    const [genus, bacteria] = name.split(/\s/);
    return `${genus.substring(0, 1).toUpperCase()}. ${bacteria}`;
  }
  return capitalize(name);
};
