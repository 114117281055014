import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN: string =
  process.env['SENTRY_DSN'] ||
  process.env['NEXT_PUBLIC_SENTRY_DSN'] ||
  'https://bd48bedc89d44895847ca59e8b81ded2@o970258.ingest.sentry.io/6171139';

Sentry.init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
