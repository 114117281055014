import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import { useEffect } from 'react';

let init = false;

export default function useGoogleTagManager(containerId: string) {
  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      init = true;
      return;
    }
    if (!init) {
      // One-time initialization
      init = true;
      Analytics({
        app: 'snapihealth',
        plugins: [
          googleTagManager({
            containerId,
          }),
        ],
      });
    }
  });
}
