import {
  LogoIcon,
  MenuMyIcon,
  NavigationIconTypes,
  NavigationIcon,
} from '@snapi/icons';
import { useAuth } from '@snapi/ui-utils';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { useToggle } from 'react-use';
import { Disclosure } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Description } from '@snapi/luxury';

export interface NavigationItemProps {
  nav: NavigationIconTypes;
  name: string;
  href: string;
}

const MenuVariants = {
  visible: { opacity: 1, display: 'block' },
  hidden: { opacity: 0, display: 'none' },
};

const NavMenuItem = ({
  nav,
  name,
  href,
}: Pick<NavigationItemProps, 'nav' | 'name' | 'href'>) => {
  const router = useRouter();

  const handleClick = () => {
    router.push(href);
  };

  return (
    <Disclosure.Button
      onClick={handleClick}
      className="flex gap-5 md:gap-1 cursor-pointer"
    >
      <span className="w-8 h-8 flex justify-center items-center bg-white rounded-[10px]">
        <NavigationIcon nav={nav} />
      </span>
      <h5 className="flex self-center text-white md:hidden">{name}</h5>
      <span className="hidden md:flex self-center">
        <Description>{name}</Description>
      </span>
    </Disclosure.Button>
  );
};

export const NavigationContent = () => {
  const {
    state: { authorized },
  } = useAuth();

  return (
    <>
      <div className="container flex flex-col p-5 sm:p-8  pb-10 h-screen md:hidden">
        <div className="flex flex-col flex-1 gap-3">
          <NavMenuItem
            nav="home"
            name="Home"
            href={authorized ? '/dashboard' : '/auth/login'}
          />
          <NavMenuItem
            nav="instructions"
            name="Instruction"
            href="/instruction"
          />
          <NavMenuItem
            nav="video"
            name="Video Instruction"
            href="https://vimeo.com/844610002/5486038a08?share=copy"
          />
          {authorized && (
            <NavMenuItem
              nav="settings"
              name="Account Settings"
              href="/settings"
            />
          )}
          <NavMenuItem
            nav="support"
            name="Support"
            href="mailto:info@snapihealth.com"
          />
        </div>
        {authorized && (
          <NavMenuItem nav="logout" name="Log out" href="/auth/logout" />
        )}
      </div>
      <div className="container hidden md:flex flex-col p-8 h-screen">
        <div className="bg-white rounded-[10px] p-1 w-[190px] flex self-end mt-10">
          <div className="flex flex-col flex-1 gap-1 text-body">
            <NavMenuItem
              nav="home"
              name="Home"
              href={authorized ? '/dashboard' : '/auth/login'}
            />
            <NavMenuItem
              nav="instructions"
              name="Instruction"
              href="/instruction"
            />
            <NavMenuItem
              nav="video"
              name="Video Instruction"
              href="https://vimeo.com/844610002/5486038a08?share=copy"
            />
            {authorized && (
              <NavMenuItem
                nav="settings"
                name="Account Settings"
                href="/settings"
              />
            )}
            <NavMenuItem
              nav="support"
              name="Support"
              href="mailto:info@snapihealth.com"
            />
            {authorized && (
              <>
                <div className="border-b border-body opacity-20" />
                <NavMenuItem nav="logout" name="Log out" href="/auth/logout" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const Navigation = () => {
  const [isMenuOpened, setMenuOpened] = useToggle(false);
  const {
    state: { authorized },
  } = useAuth();

  return (
    <Disclosure
      as="nav"
      className="fixed top-0 right-0 left-0 flex items-center h-10 md:h-[60px] bg-white z-[60]"
    >
      {({ open, close }) => (
        <>
          <div className="container">
            <div className="flex items-center justify-between">
              <div className="flex items-center pl-0 gap-10">
                <Link href={authorized ? '/dashboard' : '/auth/login'} passHref>
                  <a role="link" aria-label="Brand">
                    <LogoIcon className="h-5 sm:h-6" />
                  </a>
                </Link>
              </div>
              <div className="flex items-center space-r-10 gap-2">
                <Disclosure.Button className="relative z-40 flex items-center justify-center h-6">
                  <MenuMyIcon
                    open={isMenuOpened}
                    onClick={() => setMenuOpened()}
                  />
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel>
            <AnimatePresence>
              <motion.div
                initial="hidden"
                animate={isMenuOpened ? 'visible' : 'hidden'}
                variants={MenuVariants}
                transition={{ duration: 0.35 }}
                className="fixed top-0 bottom-0 left-0 right-0 z-30 w-screen h-screen bg-dark bg-opacity-100 overflow-hidden"
                onClick={() => {
                  setMenuOpened(!isMenuOpened); 
                  close()
                }}
              >
                <NavigationContent />
              </motion.div>
            </AnimatePresence>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};



